import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project1() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project1-section">
          <div className="project-section-inner">
            <div className="project1-text1">Moonshot Estate, Kuje, Abuja</div>
            <div className="project1-text2">
              Introducing our newest luxury real estate development, Moonshot
              Estate. Located in a fast-paced environment, this exclusive
              community offers captivating greenery views to enjoy memorable
              moment with your loved ones and easy access to key locations in
              Abuja.{" "}
            </div>

            <div className="project1-text3">
              With convenient access to major roads and highways, you will be
              able to get to where you need to go with ease.
            </div>

            <div className="project1-box">
              <div>
                <div className="project1-text4">Key Information</div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Located at Kuje,Abuja
                </div>
                <div className="project1-text5">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Plot sizes 400SQM,
                  500SQM, 600SQM
                </div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Residential &
                  Commercial Purpose
                </div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Property Title :
                  Certificate of Occupancy
                </div>

                <a href="./moon.pdf" download>
                  <button className="project1-button">
                    Download Property Brochure{" "}
                    <img
                      className="point"
                      src="./images/point.svg"
                      alt="img8"
                    />{" "}
                  </button>
                </a>
              </div>
              <img className="img8" src="./images/img8.png" alt="img8" />
            </div>

            <div className="project1-box1">
              <div className="project-box-inner">
                <img src="./images/gym.svg" alt="img8" />
                <div className="project1-text6">Facilities</div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> 24/7 Security &
                  Electricity
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Good road network
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Recreation centers
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Garden & Play areas
                </div>
              </div>
              <div className="project-box-inner">
                <img src="./images/car.svg" alt="img8" />
                <div className="project1-text6">Neighbourhood</div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Almat Farms
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Centenary smart city
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Greenery Captivating
                  views
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Kuje Ultramodern
                  Market
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="project1-section2">
          <div className="project1-section2-inner">
            <div className="project1-text9">Pricing Plan (400SQM)</div>
            <div>
              <div className="project1-section2-box">
                <div className="box1">
                  <div className="project1-text10">Outright Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text11">Initial Deposit</div>
                      <div className="project1-text12">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text11">Total Price</div>
                      <div className="project1-text12">₦3,200,000</div>
                    </div>
                  </div>
                </div>
                <div className="box1">
                  <div className="project1-text10">3-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text11">Initial Deposit</div>
                      <div className="project1-text12">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text11">Total Price</div>
                      <div className="project1-text12">₦3,500,000</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project1-section2-box">
                <div className="box1">
                  <div className="project1-text10">6-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text11">Initial Deposit</div>
                      <div className="project1-text12">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text11">Total Price</div>
                      <div className="project1-text12">₦3,800,000</div>
                    </div>
                  </div>
                </div>
                <div className="box1">
                  <div className="project1-text10">12-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text11">Initial Deposit</div>
                      <div className="project1-text12">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text11">Total Price</div>
                      <div className="project1-text12">₦4,100,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project1-section3">
          <div className="project1-section2-inner">
            <div className="project1-text91">Pricing Plan (500SQM)</div>
            <div>
              <div className="project1-section2-box">
                <div className="box1">
                  <div className="project1-text101">Outright Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦4,000,000</div>
                    </div>
                  </div>
                </div>
                <div className="box1">
                  <div className="project1-text101">3-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦4,300,000</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project1-section2-box">
                <div className="box1">
                  <div className="project1-text101">6-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦4,600,000</div>
                    </div>
                  </div>
                </div>
                <div className="box1">
                  <div className="project1-text101">12-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦4,900,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project1-section4">
          <div className="project1-section2-inner">
            <div className="project1-text91">Pricing Plan (600SQM)</div>
            <div>
              <div className="project1-section2-box">
                <div className="box1">
                  <div className="project1-text101">Outright Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦4,400,000</div>
                    </div>
                  </div>
                </div>
                <div className="box1">
                  <div className="project1-text101">3-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦4,700,000</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project1-section2-box">
                <div className="box1">
                  <div className="project1-text101">6-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦5,000,000</div>
                    </div>
                  </div>
                </div>
                <div className="box1">
                  <div className="project1-text101">12-Months Payment</div>
                  <div className="project1-section2-box-inner">
                    <div className="boxs">
                      <div className="project1-text111">Initial Deposit</div>
                      <div className="project1-text121">₦500,000</div>
                    </div>
                    <div className="boxs">
                      <div className="project1-text111">Total Price</div>
                      <div className="project1-text121">₦5,300,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="contact-section4">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
