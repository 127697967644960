import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project-section1">
          <div className="project-text1">More Value for Your Money</div>
          <div className="project-text2">
            Are you in the market for a new home? Look no further. Each of our
            projects offers the perfect blend of city living and tranquility,
            with a wide range of beautifully designed homes to choose from.
          </div>
        </section>
        <section className="project-section2">
          <div className="project-section2-inner">
            <div className="project-text3">Moonshot Estate, Kuje, Abuja</div>
            <div className="project-text4">
              Introducing our newest luxury real estate development, Moonshot
              Estate. Located in a fast-paced environment, this exclusive
              community offers captivating greenery views to enjoy memorable
              moment with your loved ones and easy access to key locations in
              Abuja.{" "}
            </div>
            <Link to="/moonshot">
              <button className="project-button1">View Project</button>
            </Link>
          </div>
          <img className="img4" src="./images/img4.png" alt="img4" />
        </section>
        <section className="project-section3">
          <div className="project-box">
            <img className="img51" src="./images/img5.png" alt="img5" />
            <div className="project-text5">
              Mint Court Estate, Millard, Mowe
            </div>
            <div className="project-text6">
              Located in a blissful vicinity at Mowe,Ogun State. This estate is
              perfect for those who value privacy and exclusivity, yet still
              want easy access to best shopping, dining, and entertainment.
              Also, with convenient access to major roads and highways, you will
              be able to get to where you need to go with ease.{" "}
            </div>
            <Link to="/mint">
              <button className="project-button2">View Project</button>
            </Link>
          </div>
          <div className="project-back"></div>
        </section>
        <img className="img5" src="./images/img5.png" alt="img5" />
        <section className="project-section4">
          <div className="project-section4-inner">
            <img className="img61" src="./images/img6.png" alt="img6" />
            <div className="project-section4-inner-flex">
              <div className="project-text7">Stake City, Kobape, Abeokuta</div>
              <div className="project-section4-box">
                <div className="project-text8">
                  Stake City combines luxury and class, with contemporary
                  designs architecturally built to befit an elite status.
                  Offering a grand comfortable living style and iconic views.
                  The Stake City is designed to provide its residents with an
                  elevated lifestyle.
                </div>
                <Link to="/stake">
                  <button className="project-button3">View Project</button>
                </Link>
              </div>
            </div>
            <img className="img6" src="./images/img6.png" alt="img6" />
          </div>
        </section>
        <section className="project-section5">
          <img className="img71" src="./images/img7.png" alt="img7" />
          <div className="project-box3">
            <div className="project-text9">Genesis Blocks, Maralago, Abuja</div>
            <div className="project-text10">
              We are excited to offer 5-Bedroom stunning duplex and 4-Bedrom
              Terrace units for sale. Each unit boasts spacious living areas,
              modern amenities, and a convenient location. Imagine yourself
              cooking in a beautiful kitchen, entertaining guests in an elegant
              living room, and relaxing in your own private balcony.
            </div>
            <Link to="/genesis">
              <button className="project-button1">View Project</button>
            </Link>
          </div>
          <div className="project-yellow"></div>
        </section>
        <img className="img7" src="./images/img7.png" alt="img7" />
        <div className="project-box3-extra">
          <div className="project-text11">Fork Hill, Kuje, Abuja</div>
          <div className="project-text12">
            Located in a prime location, the estate is just minutes away from
            Almat farms, Centenary smart city, and other key locations in Abuja.
          </div>
          <div className="project-text13">
            {" "}
            <img src="./images/lock.svg" alt="lock" /> Coming Soon
          </div>
        </div>
        <section className="project-section6">
          <div className="project-text14">
            Not sure of the property to go for ?
          </div>
          <div className="project-text15">
            We have a great team at Baker Homes waiting to help you with
            anything.
          </div>
          <Link to="/contact">
            <button className="project-button4">Talk to us</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
