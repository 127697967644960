import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Home() {
  const images = ["./images/s1.png", "./images/s2.png", "./images/s3.png"];
  const [index, setIndex] = useState(0);
  const delay = 5000;

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="body">
      <Header />
      <main>
        <section className="home-section1">
          <div
            className="slideshowSlider"
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            {images.map((image, index) => (
              <img className="slide" key={index} src={image} alt="slides" />
            ))}
          </div>
          <div className="home-text1">
            Real Estate Investment without Borders
          </div>
          <div className="home-bg">
            <div className="home-bg-text">
              Your #1 Trusted Partner in Finding Your Perfect Home
            </div>
            <div className="cflex">
              <div
                onClick={() => {
                  setIndex(0);
                }}
                className={index === 0 ? "aslide" : "circle"}
              ></div>
              <div
                onClick={() => {
                  setIndex(1);
                }}
                className={index === 1 ? "aslide" : "circle"}
              ></div>
              <div
                onClick={() => {
                  setIndex(2);
                }}
                className={index === 2 ? "aslide" : "circle"}
              ></div>
            </div>
          </div>
        </section>
        <section className="home-section2">
          <div className="home-text2">
            Providing Excellence & <span className="home-text3">A Unique</span>{" "}
            Experience
          </div>
          <div className="home-flex1">
            <div className="home-flex-inner">
              <div>
                <div className="home-box">
                  <div className="home-text4">01</div>
                  <div className="home-text5">Land/Property Verification</div>
                  <div className="home-text6">
                    We conduct land search & verify property titles to prevent
                    unlawful possession of properties.
                  </div>
                </div>

                <div className="home-box">
                  <div className="home-text4">02</div>
                  <div className="home-text5">Property Lease/Letting</div>
                  <div className="home-text6">
                    We provide commercial and residential properties for rental
                    and shortletting purposes.
                  </div>
                </div>
              </div>

              <div className="home-box3">
                <div className="home-text4">03</div>
                <div className="home-text5">Property Development</div>
                <div className="home-text6">
                  We develop properties using modern technology and designs to
                  provide luxury, comfort and security.
                </div>
              </div>
            </div>

            <div className="home-flex-inner2">
              <div className="home-yellow"></div>
            </div>
          </div>
        </section>

        <section className="home-section3">
          <div className="home-section3-box"></div>
          <div className="home-section3-box2">
            <div className="home-text7">With You Every Step of The Way</div>
            <div className="home-text8">
              We understand that building/buying a home or land can be a complex
              and overwhelming process. That's why we work closely with our
              clients to understand their unique needs and preferences, and
              provide personalized guidance every step of the way.{" "}
            </div>
            <Link to="/project">
              <button style={{ cursor: "pointer" }} className="home-button">
                View Projects
              </button>
            </Link>
          </div>
        </section>
        <section className="home-section4">
          <div className="home-text9">Where Quality matters </div>
          <div className="home-text9">Choose Baker Homes</div>

          <Link to="/contact">
            <button style={{ cursor: "pointer" }} className="home-button2">
              Talk to us
            </button>
          </Link>
        </section>
        <section className="home-section6">
          <div className="home-text13">
            Need Property & Land <span className="vrf">Verification</span>{" "}
            Services?
          </div>

          <div className="home-text14">
            We have an interdisciplinary team, consisting Lawyers, Engineers,
            Surveyors, Geotechnical Experts and Estate Valuers, to help you
            achieve your Real Estate goals.
          </div>

          <a
            target="_blank"
            href="https://verification.bakerindustries.io/"
            rel="noreferrer"
          >
            <button style={{ cursor: "pointer" }} className="butn">
              Talk to us
            </button>
          </a>
        </section>
        <section className="home-section5">
          <img className="im5" src="./images/img2.png" alt="vendor" />
          <img className="img21" src="./images/img21.png" alt="vendor" />
          <div className="home-secion5-box">
            <div className="home-text10">Vendor Pre-Qualification </div>
            <div className="home-text11">
              We are constantly recruiting the best hands to execute our
              projects, If this is you, Apply for a contract with us.
            </div>

            <Link className="home-link" to="/">
              Apply for contract
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
