import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project5() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project1-section">
          <div className="project-section-inner">
            <div className="project1-text1">Forkhill Estate, Kuje, Abuja</div>
            <div className="project5-text2">
              Forkhill Estate is a vast area of land with integration of
              compatible land uses to create an environment that is conducive,
              aesthetically pleasing, functional, recreational, and sustainable.
            </div>

            <div className="project1-box">
              <div>
                <div className="project1-text4">Key Information</div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Located at Kuje,Abuja
                </div>
                <div className="project1-text5">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Plot sizes 400SQM,
                  500SQM, 600SQM
                </div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Recreational &
                  Industrial Purpose
                </div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Residential &
                  Commercial Purpose
                </div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Residential & Open
                  space & Public use
                </div>
                <div className="project1-text5">
                  <img src="./images/t1.svg" alt="img8" /> Property Title :
                  Certificate of Occupancy
                </div>

                <a href="./moon.pdf" download>
                  <button className="project1-button">
                    Download Property Brochure{" "}
                    <img
                      className="point"
                      src="./images/point.svg"
                      alt="img8"
                    />{" "}
                  </button>
                </a>
              </div>
              <img className="img8" src="./images/img8.png" alt="img8" />
            </div>

            <div className="project1-box1">
              <div className="project-box-inner">
                <img src="./images/gym.svg" alt="img8" />
                <div className="project1-text6">Facilities</div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> 24/7 Security &
                  Electricity
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Good road network
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Recreation centers
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Garden & Play areas
                </div>
              </div>
              <div className="project-box-inner">
                <img src="./images/car.svg" alt="img8" />
                <div className="project1-text6">Neighbourhood</div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Nnamdi Azikiwe
                  International Airport
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Sub station
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Proposed train
                  station
                </div>
                <div className="project1-text7">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Centenary smart city
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="project1-section2">
          <div className="project5-section2-inner">
            <div className="project5-flex">
              <div>
                <div className="project5-text9">Price Per HA</div>
                <div className="project5-text10">₦35,000,000</div>

                <div className="project5-text11">Pricing For (400SQM)</div>
                <div className="project5-text12">₦2,000,000</div>
              </div>
              <div className="project5-box">
                <div className="project5-text11">Pricing For (300SQM)</div>
                <div className="project5-text12">₦1,500,000</div>
              </div>
            </div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project1-section3">
          <div className="project1-section2-inner">
            <div className="project1-text91">Pricing Plan (500SQM)</div>
            <div className="project1-text91">₦2,500,000</div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project6-contact">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
