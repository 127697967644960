import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project3() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project3-section1">
          <div className="project3-box1">
            <div className="project3-text1">Urban Glam & Serenity</div>
            <div className="project3-text2">Stake City, Kobape Abeokuta</div>
            <div className="project3-text3">
              Stake City combines luxury and class, with contemporary designs
              architecturally built to befit an elite status. Offering a grand
              comfortable living style and iconic views. The Stake City is
              designed to provide its residents with an elevated lifestyle.
            </div>
            <a href="./moon.pdf" download>
              <button className="project11-button">
                Download Property Brochure{" "}
                <img className="point" src="./images/point.svg" alt="img8" />{" "}
              </button>
            </a>
          </div>

          <div className="project3-box2">
            <div className="project1-text41">Key Information</div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> Located at Kobape,
              Abeokuta
            </div>
            <div className="project1-text5">
              {" "}
              <img src="./images/t1.svg" alt="img8" /> Plot sizes 450SQM, 600SQM
            </div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> Residential & Commercial
              Purpose
            </div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> Property Title : Deed &
              Land Survey
            </div>
          </div>
        </section>
        <section>
          <div className="project3-box11">
            <div className="project-box-inner">
              <img src="./images/car.svg" alt="img8" />
              <div className="project1-text6">Neighbourhood</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Prof Wole Soyinka train
                station
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Governor’s office Exp
                way
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Federal medical centre
                (FMC)
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Olusegun Obasanjo
                Hilltop GRA
              </div>
            </div>
            <div className="project-box-inner">
              <img src="./images/gym.svg" alt="img8" />
              <div className="project1-text6">Facilities</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 24/7 Security &
                Electricity
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Good road network
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Recreation centers
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Garden & Play areas
              </div>
            </div>
          </div>
        </section>
        <section className="project3-section3">
          <div className="project3-section3-inner">
            <div>
              <div className="project3-text4">Pricing Plan (450SQM)</div>

              <div className="phase1">
                <div className="project3-text5">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Phase 1 (450SQM)
                </div>
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">Initial Deposit</div>
                    <div className="project3-text7">₦500,000</div>
                  </div>
                  <div className="payment-item">
                    <div className="project3-text6">12-Months Installment</div>
                    <div className="project3-text7">₦2,875,000</div>
                  </div>

                  <div className="payment-item">
                    <div className="project3-text6">Outright</div>
                    <div className="project3-text7">₦2,500,000</div>
                  </div>
                </div>
              </div>

              <div className="phase1">
                <div className="project3-text5">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Phase 2 (450SQM)
                </div>
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">Initial Deposit</div>
                    <div className="project3-text7">₦500,000</div>
                  </div>
                  <div className="payment-item">
                    <div className="project3-text6">12-Months Installment</div>
                    <div className="project3-text7">₦2,530,000</div>
                  </div>

                  <div className="payment-item">
                    <div className="project3-text6">Outright</div>
                    <div className="project3-text7">₦2,200,000</div>
                  </div>
                </div>
              </div>
              <Link to="/register">
                <button className="project3-button">Register Interest</button>
              </Link>
            </div>

            <div>
              <div className="project3-text4">Pricing Plan (600SQM)</div>

              <div className="phase1">
                <div className="project3-text5">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Phase 1 (600SQM)
                </div>
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">Initial Deposit</div>
                    <div className="project3-text7">₦500,000</div>
                  </div>
                  <div className="payment-item">
                    <div className="project3-text6">12-Months Installment</div>
                    <div className="project3-text7">₦3,450,000</div>
                  </div>

                  <div className="payment-item">
                    <div className="project3-text6">Outright</div>
                    <div className="project3-text7">₦3,000,000</div>
                  </div>
                </div>
              </div>

              <div className="phase1">
                <div className="project3-text5">
                  {" "}
                  <img src="./images/t1.svg" alt="img8" /> Phase 2 (600SQM)
                </div>
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">Initial Deposit</div>
                    <div className="project3-text7">₦500,000</div>
                  </div>
                  <div className="payment-item">
                    <div className="project3-text6">12-Months Installment</div>
                    <div className="project3-text7">₦3,105,000</div>
                  </div>

                  <div className="payment-item">
                    <div className="project3-text6">Outright</div>
                    <div className="project3-text7">₦2,700,000</div>
                  </div>
                </div>
              </div>

              <Link to="/register">
                <button className="project3-button">Register Interest</button>
              </Link>
            </div>
          </div>
        </section>
        <section className="contact-section4">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
