import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

/* eslint-disable import/no-webpack-loader-syntax */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function Contact() {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const createReq = async (evt) => {
    evt.preventDefault();
    const id = toast.loading("Submission in progress..");
    try {
      const cred = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        message: messageRef.current.value,
      };
      const res = await axios.post(
        `https://bakerhomes.io/api/v1/hcontact`,
        cred
      );
      if (res.data.code === 200) {
        toast.update(id, {
          render: "Request Submitted Successfull",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneRef.current.value = "";
        messageRef.current.value = "";
      }
    } catch (err) {
      console.log(err);
      toast.update(id, {
        render: `${err.response}`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
      });
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="contact-section1">
          <div className="contact-text1">Talk to us</div>
          <div className="contact-text2">
            Our team is passionate about real estate and dedicated to providing
            exceptional service. We take the time to understand our clients'
            individual needs and desires, and we work tirelessly to find them
            the perfect home. From first-time homebuyers to seasoned investors,
            we are here to guide and support you every step of the way!
          </div>
        </section>
        <section className="contact-section2">
          <form onSubmit={createReq} className="contact-section2-inner">
            <div className="contact-box1">
              <div className="contact-text3">
                For general enquiry, request & complaints
              </div>

              <div className="input-box">
                <label className="label">Full Name</label>
                <input
                  ref={nameRef}
                  className="input"
                  placeholder="Enter your Full Name"
                  required
                />
              </div>

              <div className="input-box">
                <label className="label">Phone Number</label>
                <input
                  className="input"
                  ref={phoneRef}
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              <div className="input-box">
                <label className="label">Email Address</label>
                <input
                  ref={emailRef}
                  className="input"
                  placeholder="Enter your email address"
                  required
                />
              </div>

              <div className="input-box">
                <label className="label">Message</label>
                <textarea
                  ref={messageRef}
                  className="input textarea"
                  required
                ></textarea>
              </div>

              <button className="contact-button">Send Message</button>
            </div>

            <div className="contact-box1">
              <div className="detail-box">
                <div className="detail-text1">
                  <img
                    className="detail-img"
                    src="./images/phone.svg"
                    alt="phone"
                  />{" "}
                  <span className="detail-text3"> Call us </span>
                </div>
                <div className="detail-text">0803 851 2489</div>
              </div>

              <div className="detail-box">
                <div className="detail-text1">
                  <img
                    className="detail-img"
                    src="./images/email.svg"
                    alt="phone"
                  />{" "}
                  <span className="detail-text3"> Email </span>
                </div>
                <div className="detail-text">contact@bakerhomes.io</div>
              </div>

              <div className="detail-box">
                <div className="detail-text1">
                  <img
                    className="detail-img"
                    src="./images/home.svg"
                    alt="phone"
                  />{" "}
                  <span className="detail-text3"> Head Office </span>
                </div>
                <div className="detail-text">
                  No 32, Aje Street, Sabo Yaba, Lagos State
                </div>
              </div>

              <div className="detail-box">
                <div className="detail-text1">
                  <img
                    className="detail-img"
                    src="./images/home.svg"
                    alt="phone"
                  />{" "}
                  <span className="detail-text3"> Abuja Office </span>
                </div>
                <div className="detail-text">
                  Plot No 1095, Cadastral zone, E1 SHM office complex, Along
                  Kado/Mabushi express way, Mabushi District, Abuja
                </div>
              </div>

              <div className="detail-flex">
                <div className="detail-text2">Need a quicker response?</div>
                <a
                  href="https://wa.me/2347018113880"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="detail-img"
                    src="./images/what.svg"
                    alt="phone"
                  />{" "}
                </a>
              </div>
            </div>
          </form>
        </section>
        <section className="map">
          <Map
            initialViewState={{
              latitude: 9.5294871,
              longitude: 6.6221677,
              zoom: 5,
              bearing: 0,
              pitch: 0,
            }}
            mapboxAccessToken="pk.eyJ1Ijoiam9zaDQzMjQiLCJhIjoiY2tiemoyYmN2MGJ6ODJ2bXJmM25pbjN1dSJ9.veWU3GwQOzzf0OSAA_TRNg"
            width="100%"
            height="100%"
            transitionDuration="200"
            mapStyle="mapbox://styles/mapbox/streets-v9"
          >
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />
            <Marker
              latitude=" 8.8864616"
              longitude="7.2143204"
              offsetLeft={-3.5 * 12}
              offsetTop={-7 * 12}
            ></Marker>
            <Marker
              latitude="6.8057473"
              longitude="3.4357954"
              offsetLeft={-3.5 * 12}
              offsetTop={-7 * 12}
            ></Marker>
            <Marker
              latitude="7.0958577"
              longitude="3.3757523"
              offsetLeft={-3.5 * 12}
              offsetTop={-7 * 12}
            ></Marker>
            <Marker
              latitude="9.1038054"
              longitude="7.3813508"
              offsetLeft={-3.5 * 12}
              offsetTop={-7 * 12}
            ></Marker>
          </Map>
        </section>
        <section className="contact-section4">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
