import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import { toast } from "react-toastify";

export default function Register() {
  const [state, setState] = useState(false);
  const [plot, setPlot] = useState("");
  const [pay, setPay] = useState("");
  const [inspect, setInspect] = useState("");

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const cityRef = useRef();

  const nameRef1 = useRef();
  const emailRef1 = useRef();
  const phoneRef1 = useRef();
  const cityRef1 = useRef();
  const dateRef = useRef();
  const timeRef = useRef();

  const createInterest = async (evt) => {
    evt.preventDefault();
    const id = toast.loading("Submission in progress..");
    try {
      const cred = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        city: cityRef.current.value,
        plot,
        payment: pay,
      };
      const res = await axios.post(`https://bakerhomes.io/api/v1/home`, cred);
      if (res.data.code === 200) {
        toast.update(id, {
          render: "Request Submitted Successfull",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneRef.current.value = "";
        cityRef.current.value = "";
      }
    } catch (err) {
      console.log(err);
      toast.update(id, {
        render: `${err.response}`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
      });
    }
  };
  const createSchedule = async (evt) => {
    evt.preventDefault();
    const id = toast.loading("Submission in progress..");
    try {
      const cred = {
        name: nameRef1.current.value,
        email: emailRef1.current.value,
        phone: phoneRef1.current.value,
        city: cityRef1.current.value,
        inspect,
        date: dateRef.current.value,
        time: timeRef.current.value,
      };

      const res = await axios.post(
        `https://bakerhomes.io/api/v1/schedule`,
        cred
      );
      if (res.data.code === 200) {
        toast.update(id, {
          render: "Request Submitted Successfull",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        nameRef1.current.value = "";
        emailRef1.current.value = "";
        phoneRef1.current.value = "";
        cityRef1.current.value = "";
        dateRef.current.value = "";
        timeRef.current.value = "";
      }
    } catch (err) {
      console.log(err);
      toast.update(id, {
        render: `${err.response}`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
      });
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main className="register">
        <section className="register-section1">
          <div className="register-section1-inner">
            <div
              className={state ? "register-text1" : "register-active"}
              style={{ cursor: "pointer" }}
              onClick={() => setState(false)}
            >
              Register Interest
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setState(true)}
              className={state ? "register-active" : "register-text1"}
            >
              Schedule Inspection
            </div>
          </div>

          {state ? (
            <form onSubmit={createSchedule} className="inspect-section1-box ">
              <div className="inspect-text2">
                Kindly fill the form below. One of our team members will reach
                out to you shortly
              </div>

              <div>
                <div className="input-box">
                  <label className="label">Full Name</label>
                  <input
                    ref={nameRef1}
                    className="input"
                    placeholder="Enter your Full Name"
                    required
                  />
                </div>

                <div className="input-box">
                  <label className="label">Phone Number</label>
                  <input
                    ref={phoneRef1}
                    className="input"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>

                <div className="input-box">
                  <label className="label">Email Address</label>
                  <input
                    ref={emailRef1}
                    className="input"
                    placeholder="Enter your email address"
                    required
                  />
                </div>

                <div className="input-box">
                  <label className="label">City (Optional)</label>
                  <input
                    ref={cityRef1}
                    className="input"
                    placeholder="Enter your city"
                  />
                </div>

                <div className="input-box">
                  <label className="label"> You are inspecting as a/an</label>
                  <div>
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="HTML"
                      onChange={() => setInspect("Company")}
                    />
                    <label className="radio" for="html">
                      Company
                    </label>
                    <br />
                    <input
                      type="radio"
                      id="css"
                      name="fav_language"
                      value="CSS"
                      onChange={() => setInspect("Individual")}
                    />
                    <label className="radio" for="css">
                      Individual
                    </label>
                    <br />
                  </div>
                </div>

                <div className="input-box">
                  <label className="label">Date of Inspection</label>
                  <input
                    className="input"
                    type="date"
                    ref={dateRef}
                    placeholder="Enter Date of Inspection"
                    required
                  />
                </div>

                <div className="input-box">
                  <label className="label">Time of Inspection</label>
                  <input
                    className="input"
                    type="time"
                    ref={timeRef}
                    placeholder="Enter time f inspection"
                    required
                  />
                </div>

                <button className="rbut">Schedule Inspection </button>
              </div>
            </form>
          ) : (
            <form onSubmit={createInterest} className="register-section1-box">
              <div className="register-text2">
                Kindly fill the form below. One of our team members will reach
                out to you shortly
              </div>

              <div className="register-section-flex">
                <div className="register-box-inner">
                  <div className="input-box">
                    <label className="label">Full Name</label>
                    <input
                      ref={nameRef}
                      className="input"
                      placeholder="Enter your Full Name"
                      required
                    />
                  </div>

                  <div className="input-box">
                    <label className="label">Email Address</label>
                    <input
                      ref={emailRef}
                      className="input"
                      placeholder="Enter your email address"
                      required
                    />
                  </div>

                  <div className="input-box">
                    <label className="label">
                      {" "}
                      Choose preferred plot size/ Property
                    </label>
                    <div>
                      <input
                        type="radio"
                        id="html"
                        name="plot"
                        value="400 SQM"
                        onChange={() => setPlot("400 SQM")}
                      />
                      <label className="radio" for="html">
                        400 SQM
                      </label>
                      <br />
                      <input
                        onChange={() => setPlot("500 SQM")}
                        type="radio"
                        id="css"
                        name="plot"
                        value="CSS"
                      />
                      <label className="radio" for="css">
                        500 SQM
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="javascript"
                        name="plot"
                        value="JavaScript"
                        onChange={() => setPlot("600 SQM")}
                      />
                      <label className="radio" for="javascript">
                        600 SQM
                      </label>
                      <br />
                      <input
                        type="radio"
                        onChange={() => setPlot("5-Bedroom Duplex")}
                        id="css"
                        name="plot"
                        value="CSS"
                      />
                      <label className="radio" for="css">
                        5-Bedroom Duplex
                      </label>
                      <br />
                      <input
                        onChange={() => setPlot("4-Bedroom Duplex")}
                        type="radio"
                        id="css"
                        name="plot"
                        value="CSS"
                      />
                      <label className="radio" for="css">
                        4-Bedroom Duplex
                      </label>
                      <br />
                      <input
                        onChange={() => setPlot("3-Bedroom Blocks of flats")}
                        type="radio"
                        id="css"
                        name="plot"
                        value="CSS"
                      />
                      <label className="radio" for="css">
                        3-Bedroom Blocks of flats
                      </label>
                      <br />
                      <input
                        onChange={() => setPlot("2-Bedroom Blocks of flats")}
                        type="radio"
                        id="css"
                        name="plot"
                        value="CSS"
                      />
                      <label className="radio" for="css">
                        2-Bedroom Blocks of flats
                      </label>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="register-box-inner">
                  <div className="input-box">
                    <label className="label">Phone Number</label>
                    <input
                      ref={phoneRef}
                      className="input"
                      placeholder="Enter your phone number"
                      required
                    />
                  </div>

                  <div className="input-box">
                    <label className="label">City (Optional)</label>
                    <input
                      ref={cityRef}
                      className="input"
                      placeholder="Enter your city"
                    />
                  </div>

                  <div className="input-box">
                    <label className="label"> Choose a payment plan</label>
                    <div>
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="HTML"
                        onChange={() => setPay("Outright")}
                      />
                      <label className="radio" for="html">
                        Outright
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="CSS"
                        onChange={() => setPay("6 Months")}
                      />
                      <label className="radio" for="css">
                        6 Months
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="javascript"
                        name="fav_language"
                        value="JavaScript"
                        onChange={() => setPay("12 Months")}
                      />
                      <label className="radio" for="javascript">
                        12 Months
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button className="rbut2">Register Interest</button>
            </form>
          )}
        </section>
      </main>
      <Footer />
    </div>
  );
}
