import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

export default function Header() {
  const path = window.location.pathname;
  const check =
    path === "/project" ||
    path === "/mint" ||
    path === "/genesis" ||
    path === "/stake" ||
    path === "/moonshot";
  const [state, setState] = useState(false);
  const [pro, setPro] = useState(false);
  const [nav, setNav] = useState(false);

  return (
    <div className="header">
      <NavLink to="/">
        <img className="logo" src="./images/logo.svg" alt="logo" />
      </NavLink>
      <img
        onClick={() => setNav(!nav)}
        src="./images/ham.svg"
        alt="ham"
        className="ham"
      />
      <div className="header-inner">
        <div
          className={
            check
              ? "active"
              : path === "/stake"
              ? "header-text2"
              : "header-text"
          }
          onClick={() => setState(!state)}
          style={{ display: "flex", cursor: "pointer" }}
        >
          Projects{" "}
          {check ? (
            <img src="./images/dy.svg" alt="down" className="down" />
          ) : (
            <img src="./images/down.svg" alt="down" className="down" />
          )}
        </div>

        <NavLink
          className={({ isActive }) =>
            isActive
              ? "active"
              : path === "/stake"
              ? "header-text2"
              : "header-text"
          }
          to="/about"
        >
          <div>About us</div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive
              ? "active"
              : path === "/stake"
              ? "header-text2"
              : "header-text"
          }
          to="/contact"
        >
          <div>Contact </div>
        </NavLink>
      </div>
      {state ? (
        <div className="project-drop">
          <NavLink to="/project" className="project-drop-text">
            All Projects
          </NavLink>
          <NavLink to="/drip" className="project-drop-text">
            Drip City
          </NavLink>
          <NavLink to="/forkhill" className="project-drop-text">
            Forkhill
          </NavLink>
          <NavLink to="/moonshot" className="project-drop-text">
            Moonshot Estate
          </NavLink>
          <NavLink to="/mint" className="project-drop-text">
            Mint Court
          </NavLink>
          <NavLink to="/stake" className="project-drop-text">
            Stake City
          </NavLink>
          <NavLink to="/genesis" className="project-drop-text">
            Genesis Blocks
          </NavLink>
        </div>
      ) : null}

      <div className={nav ? "nav-mobile animation1" : "nav-mobile animation2"}>
        <div>
          <img
            onClick={() => setNav(!nav)}
            className="close"
            src="./images/close.svg"
            alt=""
          />
        </div>
        <div className={check ? "active" : "nav-mobile-text"}>
          <div className="nav-mobile-inner">
            Projects
            {pro ? (
              <img
                onClick={() => setPro(!pro)}
                className="plus"
                src="./images/minus.svg"
                alt="plus"
              />
            ) : (
              <img
                onClick={() => setPro(!pro)}
                className="plus"
                src="./images/plus.svg"
                alt="plus"
              />
            )}
          </div>
          {pro ? (
            <div className="pro-flex">
              <Link to="/project">
                <div className="pro">All Projects</div>
              </Link>
              <Link to="/drip" className="pro">
                <div className="pro"> Drip City</div>
              </Link>
              <Link to="/forkhill">
                <div className="pro"> Forkhill</div>
              </Link>
              <Link to="/moonshot">
                <div className="pro">Moonshot Estate</div>
              </Link>
              <Link to="/mint">
                <div className="pro">Mint Court</div>
              </Link>
              <Link to="/stake">
                <div className="pro">Stake City</div>
              </Link>
              <Link to="/genesis">
                <div className="pro">Genesis Blocks</div>
              </Link>
            </div>
          ) : null}
        </div>
        <NavLink to="/about" className="nav-mobile-text">
          About Us
        </NavLink>
        <NavLink to="/contact" className="nav-mobile-text">
          Contact
        </NavLink>
        <NavLink to="/" className="nav-mobile-text">
          Home
        </NavLink>
      </div>
    </div>
  );
}
