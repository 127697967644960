import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project4() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project4-section1"></section>
        <section className="project4-section2">
          <div className="project4-text1">The Ultimate Family Home</div>
          <div className="project4-text2">
            Genesis Blocks, Gwarimpa-Kubwa Exp Road, Abuja
          </div>
          <div className="project4-text3">
            We are excited to offer 5-Bedroom stunning duplex and 4-Bedrom
            Terrace units for sale. Each unit boasts spacious living areas,
            modern amenities, and a convenient location. Imagine yourself
            cooking in a beautiful kitchen, entertaining guests in an elegant
            living room, and relaxing in your own private balcony.
          </div>

          <div className="project4-box">
            <img src="./images/img12.png" className="img12" alt="img12" />
            <div className="project4-box2">
              <div className="project1-text4">Key Information</div>
              <div className="project1-text5">
                <img src="./images/t1.svg" alt="img8" /> Located at
                Maralago,Abuja
              </div>
              <div className="project1-text5">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 5-Bedroom Duplex
              </div>
              <div className="project1-text5">
                <img src="./images/t1.svg" alt="img8" /> 4-Bedroom Terrace
              </div>
              <div className="project1-text5">
                <img src="./images/t1.svg" alt="img8" /> Residential Purpose
              </div>
              <div className="project1-text5">
                <img src="./images/t1.svg" alt="img8" /> Property Title : C of
                O, Deed Survey & Power of Attorney
              </div>
            </div>
          </div>

          <div className="project4-box1">
            <div className="project4-box-inner">
              <img src="./images/gym.svg" alt="img8" />
              <div className="project1-text6">Facilities</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 24/7 Security &
                Electricity
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Open lounge
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Spacious balconies
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> A pent suite
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Good road network
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Recreation centers
              </div>
            </div>
            <div className="project4-box-inner">
              <img src="./images/car.svg" alt="img8" />
              <div className="project1-text6">Neighbourhood</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Close Kubuwa express
                way
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 10 mins drive from Zuma
                rock
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 25 mins drive from the
                airport
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Calm, Covenient &
                Modern
              </div>
            </div>
          </div>
        </section>
        {/*  <section className="walk1">
          <div className="walk2">
           
            <iframe
              title="play1"
              width="100%"
              height="640"
              frameborder="0"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowfullscreen
              scrolling="no"
              src="https://kuula.co/share/collection/795Qx?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            ></iframe>
            
          </div>
        </section> */}
        <section className="project1-section2">
          <div className="project42-inner">
            <div className="project1-text9">
              Selling Price (Fully Furnished 5-Bedroom Duplex)
            </div>
            <div className="project42-inner-text">₦220,000,000.00</div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project1-section4">
          <div className="project1-text9 white">
            Selling Price (Fully Furnished 4-Bedroom Terrace)
          </div>
          <div className="project42-inner-text2">₦150,000,000.00</div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project1-section2">
          <div className="project42-inner">
            <div className="project1-text9">
              Selling Price (Carcass 5-Bedroom Duplex)
            </div>
            <div className="project42-inner-text">₦170,000,000.00</div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="project1-section4">
          <div className="project1-text9 white">
            Selling Price (Carcass 4-Bedroom Terrace)
          </div>
          <div className="project42-inner-text2">₦110,000,000.00</div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>
        <section className="contact-section4">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
