import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import "./App.css";
import "./bem/home.css";
import "./bem/about.css";
import "./bem/project.css";
import "./bem/contact.css";
import "./bem/project1.css";
import "./bem/project2.css";
import "./bem/project3.css";
import "./bem/project4.css";
import "./bem/project5.css";
import "./bem/project6.css";
import "./bem/register.css";
import About from "./pages/About";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import Project1 from "./pages/Project1";
import Project2 from "./pages/Project2";
import Project3 from "./pages/Project3";
import Project4 from "./pages/Project4";
import Project5 from "./pages/Project5";
import Project6 from "./pages/Project6";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/project" element={<Project />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/moonshot" element={<Project1 />} />
        <Route exact path="/mint" element={<Project2 />} />
        <Route exact path="/stake" element={<Project3 />} />
        <Route exact path="/genesis" element={<Project4 />} />
        <Route exact path="/forkhill" element={<Project5 />} />
        <Route exact path="/drip" element={<Project6 />} />
        <Route exact path="/register" element={<Register />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer autoClose={15000} />
    </BrowserRouter>
  );
}

export default App;
