import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project6() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project6-section1">
          <div className="project6-section1-inner">
            <div className="project6-box2">
              <div className="project6-text1">Luxury in your palms </div>
              <div className="project6-text2">Drip City, Ketu Epe</div>
              <div className="project6-text3">
                "Drip City" is designed as an elite residential enclave,
                strategically positioned just 3 minutes from Ketu Epe and a
                brief 15-minute drive from Epe roundabout.{" "}
              </div>
              <button className="project6-but1 ">
                Download Property Brochure
              </button>
            </div>
            <div className="project6-box1">
              <div className="project6-text5">Key Information</div>
              <div className="project6-text4">
                <img src="./images/t1.svg" alt="img8" /> Located at Ketu, Epe
              </div>
              <div className="project6-text4">
                <img src="./images/t1.svg" alt="img8" /> Plot sizes 300SQM,
                500SQM, 600SQM
              </div>
              <div className="project6-text4">
                <img src="./images/t1.svg" alt="img8" /> Residential &
                Commercial Purpose
              </div>
              <div className="project6-text4">
                <img src="./images/t1.svg" alt="img8" /> Property Title :
                Registered Survey
              </div>
            </div>
          </div>
        </section>

        <section className="project6-section2"></section>

        <section className="project6-section3">
          <div className="project3-box11">
            <div className="project-box-inner">
              <img src="./images/car.svg" alt="img8" />
              <div className="project1-text6">Neighbourhood</div>
              <div className="project1-text7">
                <img src="./images/t1.svg" alt="img8" /> Epe film city
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Dangote Refinery
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Lekki Deep seaport,Epe
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Lekki-Epe Intl Airport
              </div>
            </div>
            <div className="project-box-inner">
              <img src="./images/gym.svg" alt="img8" />
              <div className="project1-text6">Facilities</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Prime location
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 24/7 Electricity &
                Security
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Exclusive facilities
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Good road network
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Easy access to basic
                amenities
              </div>
            </div>
          </div>
        </section>

        <section className="project3-section3">
          <div className="project3-section3-inner">
            <div>
              <div className="project3-text4">Pricing Plan (300SQM)</div>

              <div className="phase1">
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">Initial Deposit</div>
                    <div className="project3-text7">₦500,000</div>
                  </div>
                  <div className="payment-item">
                    <div className="project3-text6">12-Months Installment</div>
                    <div className="project3-text7">₦2,350,000</div>
                  </div>

                  <div className="payment-item">
                    <div className="project3-text6">Outright</div>
                    <div className="project3-text7">₦2,100,000</div>
                  </div>
                </div>
              </div>

              <div className="phase1">
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">1 year Payment plan</div>
                    <div className="project3-text7">₦2, 600,000</div>
                  </div>
                </div>
              </div>
              <Link to="/register">
                <button className="project3-button">Register Interest</button>
              </Link>
            </div>

            <div>
              <div className="project3-text4">Pricing Plan (500SQM)</div>

              <div className="phase1">
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">Initial Deposit</div>
                    <div className="project3-text7">₦500,000</div>
                  </div>
                  <div className="payment-item">
                    <div className="project3-text6">12-Months Installment</div>
                    <div className="project3-text7">₦3,750,000</div>
                  </div>

                  <div className="payment-item">
                    <div className="project3-text6">Outright</div>
                    <div className="project3-text7">₦3,500,000</div>
                  </div>
                </div>
              </div>

              <div className="phase1">
                <div className="payment-flex">
                  <div className="payment-item">
                    <div className="project3-text6">1 year Payment plan</div>
                    <div className="project3-text7">₦4,000,000</div>
                  </div>
                </div>
              </div>

              <Link to="/register">
                <button className="project3-button">Register Interest</button>
              </Link>
            </div>
          </div>
        </section>

        <section className="project1-section3">
          <div className="project1-section2-inner">
            <div className="project1-text91">Pricing Plan (600SQM)</div>

            <div className="payment-flex">
              <div className="payment-item">
                <div className="project3-text6">Initial Deposit</div>
                <div className="project3-text7">₦500,000</div>
              </div>
              <div className="payment-item">
                <div className="project3-text6">12-Months Installment</div>
                <div className="project3-text7">₦4,450,000</div>
              </div>

              <div className="payment-item">
                <div className="project3-text6">Outright</div>
                <div className="project3-text7">₦4,200,000</div>
              </div>
            </div>
            <div className="propayment-item">
              <div className="project3-text6">1 year Payment plan</div>
              <div className="project3-text7">₦4, 700,000</div>
            </div>
          </div>
          <Link to="/register">
            <button className="project1-button2">Register Interest</button>
          </Link>
        </section>

        <section className="project6-contact">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
