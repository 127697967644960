import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function About() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="about-section1">
          <div className="about-section1-inner">
            <div className="about-text1">Who we are</div>
            <div className="about-text2">
              We are a proptech company in Lagos, Nigeria. With an excelling
              culture specializing in more than Real Estate. We deliver beyond
              our customer’s expectations, giving them the opportunity to a
              world of elegance.{" "}
            </div>

            <img className="img3" src="./images/img3.png" alt="" />
          </div>
        </section>
        <section className="about-section2">
          <div className="about-section2-inner">
            <div className="about-text3">Why We Founded Baker Homes</div>
            <div className="about-text4">
              Baker Homes was founded to make a difference in the world. As a
              company, we are constantly asking ourselves how we can address the
              ever-increasing problems in the Real Estate industry, ranging from
              high transactional costs to data fragmentation/Centralization and
              cumbersome processes that should normally facilitate Real Estate
              transactions. We believe Baker Homes can scale, provide value, and
              make an impact in the world, and we have learned that it takes
              putting ourselves in your shoes, holding your hands, and using
              innovative methods to get there. A home, we believe, is more than
              four walls and a roof. It is where memories are made, dreams come
              true, and families come together. We want owning a home or plot of
              land to be as simple as going to the grocery store to get
              groceries. We want to help you own your dream home in a country
              with a land mass of 923,768 square kilometers.
            </div>
          </div>
        </section>
        <section className="about-section3">
          <div className="about-section3-box">
            <div className="about-text5">Our Mission</div>
            <div className="about-text6">
              Our mission is to continually transform the Real Estate industry
              using innovative technology and simplified processes to make life
              easier for everyone.
            </div>
          </div>
          <div className="about-section3-box">
            <div className="about-text5">Our Vision</div>
            <div className="about-text6">
              To make the dream of Real Estate ownership a reality for everyone.
            </div>
          </div>
        </section>
        <section className="about-section4">
          <div className="about-text7">Meet The Team</div>
          <div className="profile-list">
            <div className="profile">
              <img className="pimg" src="./images/p1.png" alt="profile" />
              <div className="tab">
                <div>
                  <div className="about-text8">Bakare Folarin </div>
                  <div className="about-text9">CEO / Founder</div>
                </div>
                <a
                  href="https://www.linkedin.com/in/folarin-bakare-0363561b6/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="lin" src="./images/lin.svg" alt="profile" />
                </a>
              </div>
            </div>
            <div className="profile">
              <img className="pimg" src="./images/p3.png" alt="profile" />
              <div className="tab">
                <div>
                  <div className="about-text8">Kingsley I. Ifoga </div>
                  <div className="about-text9">General Manager</div>
                </div>
                <a
                  href="https://www.linkedin.com/in/kingsleyifoga"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="lin" src="./images/lin.svg" alt="profile" />
                </a>
              </div>
            </div>
            <div className="profile">
              <img className="pimg" src="./images/p2.png" alt="profile" />
              <div className="tab">
                <div>
                  <div className="about-text8">Osundina Oluwasegun </div>
                  <div className="about-text9">
                    Manager, Projects & Products Development
                  </div>
                </div>
                <a
                  href=" https://www.linkedin.com/in/olusegun-osundina-bb7637142"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="lin" src="./images/lin.svg" alt="profile" />
                </a>
              </div>
            </div>
            <div className="profile">
              <img className="pimg" src="./images/k3.png" alt="profile" />
              <div className="tab">
                <div>
                  <div className="about-text8">Kenny Adelakun</div>
                  <div className="about-text9">
                    Head, Growth & Business Development
                  </div>
                </div>
                <a href="" target="_blank" rel="noreferrer">
                  <img className="lin" src="./images/lin.svg" alt="profile" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
