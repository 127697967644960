import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Project2() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <main>
        <section className="project2-section">
          <div className="project2-section-box">
            <div className="project2-text1">Rare Quality & Style</div>
            <div className="project2-text2">
              Mint Court Estate Millard, Mowe
            </div>
          </div>
        </section>
        <section className="project2-section2">
          <div className="project2-section2-inner">
            <div className="project2-text3">
              Located in a serene vicinity at Mowe, Ogun State. This estate is
              perfect for those who value privacy and exclusivity, yet still
              want easy access to best shopping, dining, and entertainment.
              Also, with convenient access to major roads and highways, you will
              be able to get to where you need to go with ease.{" "}
            </div>
            <img src="./images/img10.png" alt="img10" />
          </div>
          <div className="project2-box2">
            <div className="project1-text41">Key Information</div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> Located at Millard, Mowe
            </div>
            <div className="project1-text5">
              {" "}
              <img src="./images/t1.svg" alt="img8" /> 3-Bedroom Blocks of Flats
            </div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> 2-Bedroom Blocks of Flats
            </div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> Residential & Commercial
              Purpose
            </div>
            <div className="project1-text5">
              <img src="./images/t1.svg" alt="img8" /> Property Title :
              Registered Survey
            </div>
            <a href="./moon.pdf" download>
              <button className="project1-button">
                Download Property Brochure{" "}
                <img className="point" src="./images/point.svg" alt="img8" />{" "}
              </button>
            </a>
          </div>

          <div className="project2-box1">
            <div className="project-box-inner">
              <img src="./images/gym.svg" alt="img8" />
              <div className="project1-text6">Facilities</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> 24/7 Security &
                Electricity
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Good road network
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Recreation centers
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Garden & Play areas
              </div>
            </div>
            <div className="project-box-inner">
              <img src="./images/car.svg" alt="img8" />
              <div className="project1-text6">Neighbourhood</div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> RCCG Camp
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Close to Lagos-Ibadan
                Exp way
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Serene Environment
              </div>
              <div className="project1-text7">
                {" "}
                <img src="./images/t1.svg" alt="img8" /> Relaxing &
                Accommodating
              </div>
            </div>
          </div>
          <div>
            <div className="project2-text4">
              Selling Price (3-Bedroom Blocks of Flats)
            </div>
            <div className="project2-text5">₦32,134,137.02</div>
            <Link to="/register">
              <button className="contact-button2">Register Interest</button>
            </Link>
          </div>
        </section>
        <section className="project2-section3">
          <div>
            <div className="project2-text6">
              Selling Price (2-Bedroom Blocks of Flats)
            </div>
            <div className="project2-text7">₦25,352,566.74</div>
            <Link to="/register">
              <button className="contact-button2">Register Interest</button>
            </Link>
          </div>
        </section>
        <section className="contact-section4">
          <div className="contact-text4 ">
            Don't miss out on the opportunity to own your dream home!
          </div>
          <Link to="/register">
            <button className="contact-button2">Register Interest</button>
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}
