import React from "react";
import Header from "../components/Header";

export default function NotFound() {
  return (
    <div className="body">
      <Header />
      <main></main>
    </div>
  );
}
